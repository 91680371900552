import React from 'react';
import './Navigation.css';

function Navigation() {
    return (
        <nav className="nav-links">
            <a href="#who-we-are">Who We Are</a>
            <a href="#services">Services</a>
            <a href="#contact">Contact</a>
            <a href="#hire-us" className="hire-us">Hire Us</a>
        </nav>
    );
}

export default Navigation;
