import React, { useState } from 'react';
import './Header.css';
import Logo from './assests/logoo.png'; // Importing logo

function Header() {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    return (
        <header className="header">
            {/* Left: Logo */}
            <div className="header-logo">
                <img src={Logo} alt="BugXTech Logo" />
            </div>

            {/* Right: Navigation */}
            <nav className="header-nav">
                <ul className="nav-list">
                    <li
                        className="nav-item"
                        
                    >
                        <span className="nav-link">Services</span>
                        {dropdownVisible && (
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="#web-services" className="dropdown-link">
                                        Web Services
                                    </a>
                                </li>
                                <li>
                                    <a href="#app-services" className="dropdown-link">
                                        App Services
                                    </a>
                                </li>
                                <li>
                                    <a href="#digital-solutions" className="dropdown-link">
                                        Digital Solutions
                                    </a>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <a href="#who-are-we" className="nav-link">
                            Who Are We
                        </a>
                    </li>
                    <li>
                        <a href="#hire-us" className="hire-us-btn">
                            Hire Us
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
