import React, { useState } from 'react';
import './Services.css';
import RequirementAnalysis from './RequirementAnalysis';
import '@fortawesome/fontawesome-free/css/all.css';

function Services() {
    const [activeService, setActiveService] = useState(null);

    const services = [
        {
            name: 'Web Development',
            description: 'Create stunning and responsive websites tailored to your business needs.',
            icon: 'fa-solid fa-code',
            key: 'web',
            technologies: ['HTML5', 'CSS3', 'JavaScript', 'ReactJS', 'Next.js', 'Angular', 'Vue.js', 'Node.js', 'Express.js', 'MongoDB', 'MySQL', 'Firebase', 'Tailwind CSS', 'Bootstrap'],
        },
        {
            name: 'App Development',
            description: 'Build scalable and high-performance mobile applications for Android and iOS.',
            icon: 'fa-solid fa-mobile-alt',
            key: 'app',
            technologies: ['Swift', 'Kotlin', 'React Native', 'Flutter', 'Dart', 'Java', 'Objective-C', 'Xamarin', 'Ionic', 'Unity', 'ARKit', 'ARCore', 'Firebase', 'AWS Amplify'],
        },
        {
            name: 'Data Science Solutions',
            description: 'Leverage data-driven insights to empower your business strategies.',
            icon: 'fa-solid fa-chart-line',
            key: 'data',
            technologies: ['Python', 'R', 'SQL', 'TensorFlow', 'PyTorch', 'Scikit-Learn', 'Pandas', 'NumPy', 'Power BI', 'Tableau', 'Azure ML', 'AWS SageMaker', 'BigQuery'],
        },
    ];

    const packages = {
        web: [
            {
                title: 'Basic',
                price: 'PKR 27,735',
                description: '1-page responsive website with smooth animations.',
                features: ['2-day delivery', '2 Revisions', '1 page', 'Content upload', 'Speed optimization'],
            },
            {
                title: 'Standard',
                price: 'PKR 143,052',
                description: 'Feature-rich 5-page website with modern animations.',
                features: ['7-day delivery', '2 Revisions', '5 pages', 'Payment processing', 'Social media icons'],
            },
            {
                title: 'Premium',
                price: 'PKR 262,749',
                description: '10-page advanced website with clean code and high performance.',
                features: ['14-day delivery', '2 Revisions', '10 pages', 'Functional website', 'Ad network integration'],
            },
            {
                title: 'Custom Package',
                price: '',
                description: 'Tailored solutions for unique requirements.',
                features: ['Flexible delivery time', 'Custom revisions', 'Dedicated project consultation', 'Integration with third-party APIs', 'Specific design and functionality requests'],
                button: true,
            },
        ],
        app: [
            {
                title: 'Basic',
                price: 'PKR 43,792',
                description: 'Simple AR app for Android/IOS with a 3D model.',
                features: ['5-day delivery', '1 Revision', 'App icon', 'Functional Android/IOS app'],
            },
            {
                title: 'Standard',
                price: 'PKR 143,052',
                description: 'Feature-rich app with optimized performance for Android/IOS.',
                features: ['7-day delivery', '2 Revisions', 'App submission', 'Ad network integration'],
            },
            {
                title: 'Premium',
                price: 'PKR 437,914',
                description: 'Advanced AR app with marker-less AR and cloud-based solutions.',
                features: ['7-day delivery', '5 Revisions', 'Location-based AR', 'Source code'],
            },
            {
                title: 'Custom Package',
                price: '',
                description: 'Tailored mobile app development for unique needs.',
                features: ['Flexible delivery time', 'Custom revisions', 'Advanced AR/VR features', 'Specific platform integrations', 'Dedicated consultation'],
                button: true,
            },
        ],
        data: [
            {
                title: 'Data Science',
                price: 'PKR 29,220',
                description: 'Structured data analysis for actionable insights.',
                features: ['3-day delivery'],
            },
            {
                title: 'Machine Learning',
                price: 'PKR 58,439',
                description: 'Machine learning solutions for structured and unstructured data.',
                features: ['7-day delivery'],
            },
            {
                title: 'Deep Learning',
                price: 'PKR 146,097',
                description: 'Deep learning models to solve complex business problems.',
                features: ['10-day delivery'],
            },
            {
                title: 'Custom Package',
                price: '',
                description: 'Custom AI and data solutions tailored to your needs.',
                features: ['Custom delivery timelines', 'Specific algorithm implementation', 'Advanced analytics dashboards', 'Dedicated data consultation', 'Third-party integrations'],
                button: true,
            },
        ],
    };

    const scrollToRequirementSection = () => {
        const requirementSection = document.getElementById('requirement-analysis');
        if (requirementSection) {
            requirementSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const technologyIcons = {
        HTML5: "fa-html5",
        CSS3: "fa-css3-alt",
        JavaScript: "fa-js",
        ReactJS: "fa-react",
        "Next.js": "fa-react",
        Angular: "fa-angular",
        "Vue.js": "fa-vuejs",
        "Node.js": "fa-node-js",
        MongoDB: "fa-database",
        MySQL: "fa-database",
        Firebase: "fa-fire",
        Bootstrap: "fa-bootstrap",
        Python: "fa-python",
        SQL: "fa-database",
    };
    return (
        <div className="services-container">
            {!activeService && (
                <section className="services-overview">
                    <h1>Our Services</h1>
                    <div className="services-cards">
                        {services.map((service) => (
                            <div key={service.key} className="service-card">
                                <div className="service-icon">
                                    <i className={service.icon}></i>
                                </div>
                                <h2>{service.name}</h2>
                                <p>{service.description}</p>
                                <div
                                    className="learn-more-arrow"
                                    onClick={() => setActiveService(service.key)}
                                >
                                    <span className="arrow">→</span>
                                    <span className="learn-more">Learn More</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            )}

            {activeService && (
                <section className="service-section">
                    <div className="service-header">
                        <button
                            className="back-button"
                            onClick={() => setActiveService(null)}
                        >
                            ← Back
                        </button>
                        <h1>{services.find((s) => s.key === activeService).name}</h1>
                    </div>
                    <div className="packages-table">
                        {packages[activeService].map((plan, index) => (
                            <div className="package-column" key={index}>
                                <h2>{plan.title}</h2>
                                <p className="price">{plan.price || 'Contact Us'}</p>
                                <p className="description">{plan.description}</p>
                                <ul className="features">
                                    {plan.features.map((feature, i) => (
                                        <li key={i}>{feature}</li>
                                    ))}
                                </ul>
                                {plan.button && (
                                    <button
                                        className="custom-package-button"
                                        onClick={scrollToRequirementSection}
                                    >
                                        Submit Requirements
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="technologies-grid">
    <h2>Technologies We Use</h2>
    <div className="technologies-icons">
        {services
            .find((s) => s.key === activeService)
            .technologies.map((tech, index) => (
                <div key={index} className="technology-box">
                    <i className={`fab ${technologyIcons[tech] || "fa-code"}`}></i>
                    <span>{tech}</span>
                </div>
            ))}
    </div>
</div>;
                </section>
                
            )}
            <section id="requirement-analysis" className="Requirement-analysis">
                <RequirementAnalysis />
            </section>
        </div>
    );
}

export default Services;
