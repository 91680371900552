import React, { useState, useEffect } from 'react';
import './HeroSection.css';

const images = [
    {
        src: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        text: 'At BugXTech, we build relationships based on reliability and innovation.',
    },
    {
        src: 'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        text: 'Get professional web, app, and data science services at 65% lower rates.',
    },
    {
        src: 'https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        text: "Revolutionize your digital presence with BugXTech's one-click solutions.",
    },
];

function HeroSection() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds
        return () => clearInterval(interval);
    }, []);

    const handleButtonClick = () => {
        document.getElementById('services').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <section className="hero-section">
            <div className="hero-image-container">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`hero-image ${currentImageIndex === index ? 'active' : ''}`}
                        style={{ backgroundImage: `url(${image.src})` }}
                    >
                        {currentImageIndex === index && (
                            <div className="hero-text">{image.text}</div>
                        )}
                    </div>
                ))}
            </div>
            <button className="get-it-now-button" onClick={handleButtonClick}>
                Get It Now
            </button>
        </section>
    );
}

export default HeroSection;
