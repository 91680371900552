import React, { useEffect, useRef } from 'react';
import './RequirementAnalysis.css';
import { gsap } from 'gsap';


function RequirementAnalysis() {
    const stepsRef = useRef([]);

    useEffect(() => {
        // Animate steps one by one
        gsap.fromTo(
            stepsRef.current,
            { opacity: 0, y: 20 },
            {
                opacity: 1,
                y: 0,
                stagger: 6, // 0.5 seconds delay between steps
                duration: 5,
                ease: 'power3.out',
            }
        );
    }, []);

    return (
        <section id="requirement-analysis" className="requirement-analysis-section">
            <div className="requirement-analysis-container">
                {/* Left Side: Form */}
                <div className="requirement-form-container">
                    <h2>Share Your Requirements</h2>
                    <form className="requirement-form">
                        <div className="budget-input-wrapper">
                            <label htmlFor="budget">Budget (in USD)</label>
                            <div className="dollar-sign">$</div>
                            <input
                                type="number"
                                id="budget"
                                placeholder="Enter your budget"
                                min="0"
                                step="0.01"
                                required
                            />
                        </div>

                        <label htmlFor="name">Your Name</label>
                        <input type="text" id="name" placeholder="Enter your name" required />

                        <label htmlFor="email">Your Email</label>
                        <input type="email" id="email" placeholder="Enter your email" required />

                        <label htmlFor="technology">Preferred Technologies</label>
                        <input
                            type="text"
                            id="technology"
                            placeholder="Enter preferred technologies"
                            required
                        />

                        <label htmlFor="requirements">Additional Requirements</label>
                        <textarea
                            id="requirements"
                            rows="5"
                            placeholder="Describe your project requirements"
                            required
                        ></textarea>

                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                </div>

                {/* Right Side: Steps */}
                <div className="requirement-steps-container">
                    <h2>Our Process</h2>
                    <ul className="steps-list">
                        {[
                            "Discuss and analyze your requirements",
                            "Suggest the best technologies and solutions",
                            "Provide a detailed project roadmap",
                            "Deliver tailored and scalable solutions",
                            "Offer post-launch support and optimization",
                        ].map((step, index) => (
                            <li key={index} ref={(el) => (stepsRef.current[index] = el)}>
                                <span>{index + 1}</span> {step}
                                {index < 4 && <div className="step-arrow">↓</div>}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default RequirementAnalysis;
