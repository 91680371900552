import React from 'react';
import Header from './components/Header';
import Navigation from './components/Navigation';
import HeroSection from './components/HeroSection';
import Services from './components/Services';

import './App.css';

function App() {
    return (
        <div className="App">
            <Header />
            <HeroSection />
            <section id="services" style={{ padding: '50px', textAlign: 'center' }}>
                <Services />
            </section>
        </div>
    );
}

export default App;
